import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';

const importExportEndpoints: Endpoint[] = [
    {
        type: 'POST',
        endpoint: '/import/:name',
        link: '#import-csv',
        tooltip: 'Import data from CSV file',
    },
    {
        type: 'GET',
        endpoint: '/export/:name',
        link: '#export-csv',
        tooltip: 'Export data to CSV or JSON file',
    },
    {
        type: 'GET',
        endpoint: '/export/:name/header',
        link: '#export-header-csv',
        tooltip: 'Export headers to CSV or JSON file',
    },
    {
        type: 'GET',
        endpoint: '/data-query',
        link: '#data-query-body',
        tooltip: 'Execute data query',
    },
    {
        type: 'GET',
        endpoint: '/data-query',
        link: '#data-query-param',
        tooltip: 'Execute data query',
    }
];

export const importExportHeader: HeaderData = {
    title: 'Imports and Exports',
    description: 'This handles importing and exporting data from CSV files. ' +
        'In the request, the name parameter should be replaced by the case-sensitive default name of the CSV import/export with "-" replacing any spaces. ' +
        'Example: Sales Order Details -> Sales-Order-Details',
    linkName: 'Imports and Exports',
    link: 'https://help.fishbowlinventory.com/hc/en-us/articles/360043109853-Imports-and-Exports',
    endpoints: importExportEndpoints,
};

export const importExportAttributes: Attribute[] = [];
