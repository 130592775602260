import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const salesOrdersEndpoints: Endpoint[] = [
    {
        type: 'DELETE',
        endpoint: '/sales-orders/:id',
        link: '#delete-sales-order',
        tooltip: 'Delete a sales order',
    }
];

export const salesOrdersHeader: HeaderData = {
    title: 'Sales Orders',
    description: 'This is an object representing a Fishbowl sales order.',
    linkName: 'Sales Orders',
    link: 'https://www.fishbowlinventory.com/wiki/Sales_Order',
    endpoints: salesOrdersEndpoints,
};

export const jsonObject: Json = {
    title: 'THE SALES ORDER OBJECT',
    json: {},
};

export const salesOrderAttributes: Attribute[] = [
];
