import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const deletePartRequest: RequestExample = {
    title: {
        verb: 'DELETE',
        endpointUrl: '/api/parts/:id',
    },
    curl:
        `curl --location \\
--request DELETE '<YOUR SERVER>/api/parts/:id' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const deletePartResponse: Json = {
    title: 'RESPONSE',
    json: '',
};

const data: RequestDefinitionData = {
    callName: 'delete-part',
    title: 'Delete part',
    description: 'Deletes the part with the specified ID.',
    attributes: [],
    requestObject: deletePartRequest,
    responseObject: deletePartResponse,
};

export default data;
