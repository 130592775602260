import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import ObjectDefinition from '../ObjectDefinition';
import {jsonObject, paymentsAttributes, paymentsHeader} from '../../data/payments/payment';
import RequestDefinition from "../RequestDefinition";
import deletePaymentsRequest from "../../data/payments/deletePaymentsRequest";

export default new RouteComponent(`${apiRoutePrefix}/payments`, () => {
    const requests = [
        deletePaymentsRequest,
    ];
    return (
        <div>
            <ObjectHeader data={ paymentsHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <ObjectDefinition data={ {
                title: 'The payments object',
                attributes: paymentsAttributes,
                jsonObject: jsonObject
            } }/>

            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data} />
                    </>
                ))
            }
        </div>
    );
});
