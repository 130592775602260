import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';

import {importExportHeader} from '../../data/importExport/importExport';
import importRequest from '../../data/importExport/importRequest';
import importRequestJson from '../../data/importExport/importRequestJson';
import dataQueryBody from '../../data/importExport/dataQueryBody';
import dataQueryParam from '../../data/importExport/dataQueryParam';
import exportRequest from "../../data/importExport/exportRequest";
import exportHeaderRequest from "../../data/importExport/exportHeaderRequest";

export default new RouteComponent(`${apiRoutePrefix}/import`, () => {

    const requests = [
        importRequest,
        importRequestJson,
        exportRequest,
        exportHeaderRequest,
        dataQueryBody,
        dataQueryParam
    ];

    return (
        <div>
            <ObjectHeader data={ importExportHeader }/>
            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data} />
                    </>
                ))
            }
        </div>
    );
});
