import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const paymentsEndpoints: Endpoint[] = [
    {
        type: 'DELETE',
        endpoint: '/payments/:id',
        link: '#delete-payment',
        tooltip: 'Delete a payment',
    }
];

export const paymentsHeader: HeaderData = {
    title: 'Payment',
    description: 'This is an object representing a Fishbowl Payment.',
    linkName: 'Payment',
    link: 'https://www.fishbowlinventory.com/wiki/Payment-Viewer',
    endpoints: paymentsEndpoints,
};

export const jsonObject: Json = {
    title: 'THE PAYMENT OBJECT',
    json: {},
};

export const paymentsAttributes: Attribute[] = [
];
