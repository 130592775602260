import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const productEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/products/:id/best-price',
        link: '#get-product-best-price',
        tooltip: 'Get the best price for the product',
    },
    {
        type: 'DELETE',
        endpoint: '/products/:id',
        link: '#delete-product',
        tooltip: 'Delete product',
    },
];

export const productHeader: HeaderData = {
    title: 'Product',
    description: 'This is an object representing a Fishbowl product.',
    linkName: 'Product',
    link: 'https://www.fishbowlinventory.com/wiki/Product',
    endpoints: productEndpoints,
};

export const jsonObject: Json = {
    title: 'THE PRODUCT OBJECT',
    json: {}
};

export const productAttributes: Attribute[] = [];
