import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';
import {Attribute} from '../../components/ObjectAttribute';

const exportRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/export/:name',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/export/:name' \\
--header 'Accept: text/csv' \\
--header 'Authorization: Bearer <TOKEN>'
    `,
};

const exportResponse: Json = {
    title: 'RESPONSE',
    json: '"header1","header2","header3"\n"data1","data2","data3"',
};

const exportAttributes: Attribute[] = [

]

const data: RequestDefinitionData = {
    callName: 'export-csv',
    title: 'Export data to CSV or JSON',
    description: 'Export data as CSV or JSON. Set the "Accept" header to either text/csv or application/json. ' +
        'In the request, the name parameter should be replaced by the case-sensitive default name of the CSV import/export with "-" replacing any spaces. ' +
        'Example: Sales Order Details -> Sales-Order-Details',
    attributes: exportAttributes,
    requestObject: exportRequest,
    responseObject: exportResponse,
}

export default data;
