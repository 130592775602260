import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';

import {partHeader} from '../../data/part/part';
import getPartBestCostRequest from '../../data/part/getPartBestCost';
import searchPartsRequest from '../../data/part/searchPartsRequest';
import deletePartRequest from "../../data/part/deletePartRequest";

export default new RouteComponent(`${apiRoutePrefix}/parts`, () => {
    const requests = [
        searchPartsRequest,
        getPartBestCostRequest,
        deletePartRequest,
    ];

    return (
        <div>
            <ObjectHeader data={ partHeader }/>
            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data} />
                    </>
                ))
            }
        </div>
    );
});
