import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import ObjectDefinition from '../ObjectDefinition';
import {jsonObject, salesOrderAttributes, salesOrdersHeader} from '../../data/salesOrders/salesOrder';
import deleteSalesOrdersRequest from '../../data/salesOrders/deleteSalesOrdersRequest';
import RequestDefinition from "../RequestDefinition";

export default new RouteComponent(`${apiRoutePrefix}/sales-orders`, () => {
    const requests = [
        deleteSalesOrdersRequest,
    ];
    return (
        <div>
            <ObjectHeader data={ salesOrdersHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <ObjectDefinition data={ {
                title: 'The sales order object',
                attributes: salesOrderAttributes,
                jsonObject: jsonObject
            } }/>

            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data} />
                    </>
                ))
            }
        </div>
    );
});
