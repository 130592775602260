import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';

import {productHeader} from '../../data/product/product';
import getProductBestPriceRequest from '../../data/product/getProductBestPrice';
import deleteProductRequest from "../../data/product/deleteProductRequest";

export default new RouteComponent(`${apiRoutePrefix}/products`, () => {
    const requests = [
        getProductBestPriceRequest,
        deleteProductRequest,
    ];

    return (
        <div>
            <ObjectHeader data={ productHeader }/>
            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data} />
                    </>
                ))
            }
        </div>
    );
});
