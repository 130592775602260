import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from "../../components/RequestDefinition";

const requestExample: RequestExample = {
    title: {
        verb: 'DELETE',
        endpointUrl: '/api/sales-orders/:id',
    },
    curl:
        `curl --location \\
--request DELETE '<YOUR SERVER>/api/sales-orders/:id' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const response: Json = {
    title: 'RESPONSE',
    json: '',
};

const data: RequestDefinitionData = {
    callName: 'delete-sales-order',
    title: 'Delete a sales order',
    description: 'Deletes the sales order with the specified ID.',
    attributes: [],
    requestObject: requestExample,
    responseObject: response,
};

export default data;
