import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from "../../components/RequestDefinition";

const requestExample: RequestExample = {
    title: {
        verb: 'DELETE',
        endpointUrl: '/api/payments/:id',
    },
    curl:
        `curl --location \\
--request DELETE '<YOUR SERVER>/api/payments/:id?merchantId=abc' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const response: Json = {
    title: 'RESPONSE',
    json: '',
};

const data: RequestDefinitionData = {
    callName: 'delete-payment',
    title: 'Delete a payment',
    description: 'Deletes the Payment with the specified ID.',
    attributes: [],
    requestObject: requestExample,
    responseObject: response,
    queryParameters: [{
        name: 'merchantId',
        type: 'string',
        description: 'If using Clover Connect, this will be the unique ID assigned to the merchant.',
        optional: true,
    }],
};

export default data;
